<template>
    <v-radio-group :dark="dark" v-model="answer" @change="$emit('change')">
        <template v-for="(choice, i) in mcqItems">
            <v-radio
                v-if="choice"
                @click="handleClickInput"
                :key="i"
                :id="(choice + i).toString()"
                :label="choice"
                :color="survey.style.color || survey.font_color || primary"
                :value="choice"
            />
        </template>
    </v-radio-group>
</template>

<script>
import {shuffleArray} from '@/utils/arrayOperations';
export default {
    name: "MultipleChoice",
    props: {
        value: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        contrastTextColor: {
            required: true,
        },
        dark: {
            required: true,
        },
        survey: {
            required: true,
        },
    },
    computed: {
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val) {
                    this.$emit("input", { type: "choice", value: val });
                } else {
                    this.$emit("input", null);
                }
            },
        },
        mcqItems() {
            let items = [];
            if(this.activeStep?.properties?.randomize_options){
                const multipleChoiceItems = this.activeStep.multipleChoiceItems;
                const excludedItems = this.activeStep?.properties?.excluded_from_randomization;
                const fixedIndices = excludedItems && excludedItems.length? multipleChoiceItems.reduce((indices, item, index) => {
                    if (excludedItems.includes(item.value)) {
                        indices.push(index);
                    }
                    return indices;
                }, []): [];

                items = shuffleArray(this.activeStep.multipleChoiceItems, fixedIndices);
            } else {
                items = this.activeStep.multipleChoiceItems;
            }
            return new Set(Array.from(items, choice => choice.value));
        }
    },
    methods: {
        handleClickInput(e) {
            let val = e.target && e.target.outerText ? e.target.outerText : e.target.previousSibling._value
            if(val === this.answer) {
                this.answer = null;
            }
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
