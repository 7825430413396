<template>
    <transition name="message">
        <div class="mt-5 d-flex">
            <v-avatar class="mr-4" :color="styles.dark?'#ffffffe6':'#000000e6'" size="44">
                <v-icon :color="styles.background">mdi-robot</v-icon>
            </v-avatar>
            <div>
                <typing-text-effect v-if="typingEffect" :loading="message.loading" @typingStarted="finishedTyping=false" :message="messageText" @typingFinished="finishedTyping=true"></typing-text-effect>
                <div v-else>
                    <p>{{ messageText }}</p>
                </div>
                <v-fade-transition>
                    <voice-response v-if="(finishedTyping || !typingEffect) && !message.loading && message.followup && !message.sufficient" 
                        :activeStep="activeStep"
                        v-model="answer"
                        :contrastTextColor="styles.backgroundTextColor"
                        :dark="styles.dark"
                        followup
                    ></voice-response>
                </v-fade-transition>
            </div>
        </div>
    </transition>
</template>

<script>
import { mapGetters } from 'vuex';

import TypingTextEffect from '../TypingTextEffect.vue';
import VoiceResponse from './VoiceResponse.vue';

const phrases = [
    'Just a sec, thinking',
    'Hmm, give me a second', 
    'Interesting, let me ponder that', 
    'Hmm... Let\'s see',
    'Just a moment while I think', 
    'Let me just put my thinking cap on', 
    'Hold on, turning my gears', 
    'Ah, let me chew on that', 
    'Let me sift through my thoughts', 
    'Okay, processing your point', 
    'Hang on, I\'m shuffling my thoughts', 
    'Ruminating on your words', 
    'Let me digest that for a moment', 
    'Your input has got my wheels spinning'
];

let nextButtonPhrases = [
    "Great response. 👍 When you're ready, click 'Next' for our next topic.",
    "Thanks for your input. ✅ Please press 'Next' to proceed.",
    "Interesting. 🧐 When you're ready, hit 'Next' to explore another question.",
    "Good job! ✅ Feel free to click on 'Next' when you're ready to move on.",
    "That was insightful! 💡 Please press 'Next' for the following question.",
    "Thanks for sharing! 😊 Let's proceed when you hit the 'Next' button.",
    "I appreciate your response. 👏 Click 'Next' when you're ready for another question.",
    "Thanks! 👌 When you're ready, you can proceed by pressing 'Next'."
];

let submitAnswerPhrases = [
    "Great, thanks for your input. ✅ Feel free to click 'Submit Answer' to finalize your response.",
    "Thanks for your detailed answer. 😊 When you're ready, hit 'Submit Answer' to complete the form.",
    "Excellent, we've covered everything. 🎯 Please press 'Submit Answer' to finalize your input.",
    "Thanks for your cooperation. 👍 You can now click 'Submit Answer' to complete the process.",
    "You've been very helpful. 🌟 Click 'Submit Answer' when you're ready to submit your response.",
    "Perfect, that's all we needed. 👌 Please hit 'Submit Answer' to finalize your input."
];

let finishButtonPhrases = [
    "Excellent, we've covered everything. 🎯 Feel free to click 'Finish' to complete the form.",
    "Thanks for your detailed responses. 😊 When you're ready, hit 'Finish' to submit.",
    "Great job answering all the questions. ✅ Please press 'Finish' to finalize the form.",
    "Thanks for your cooperation. 👍 You can now click 'Finish' to complete the process.",
    "You've been very helpful. 🌟 Click 'Finish' when you're ready to submit the form.",
    "Perfect, that's all we needed. 👌 Please hit 'Finish' to complete your input."
];

export default {
    name: 'FollowUpMessage',
    props: ['message', 'nextMessage', 'activeStep', 'messageLook', 'typingEffect'],
    components: {TypingTextEffect, VoiceResponse},
    computed: {
        ...mapGetters({
            styles: 'form/styles',
            singleQuestionWidget: 'form/singleQuestionWidget',
        }),
        messageText(){
            if(this.message.loading){
                return phrases[Math.floor(Math.random() * phrases.length)];
            }
            if(this.message.sufficient){

                if(this.singleQuestionWidget){
                    return submitAnswerPhrases[Math.floor(Math.random() * submitAnswerPhrases.length)];
                }

                // find element by id finish-button and check if it exists
                let finishButton = document.getElementById('finish-button');
                if(finishButton){
                    return finishButtonPhrases[Math.floor(Math.random() * finishButtonPhrases.length)];
                }
                
                return nextButtonPhrases[Math.floor(Math.random() * nextButtonPhrases.length)];
            }
            if(this.message.followup){
                return this.message.followup;
            }
            
            return 'Thinking...'
        },
        answer: {
            get() {return this.answerValue},
            set(answerValue) {
                if(!answerValue){
                    this.answerValue = null;
                    return;
                }
                this.answerValue = answerValue.answer || answerValue
            },
        },
    },
    data() {
        return {
            answerValue: this.nextMessage?.answer || null,
            disableTextResponse: false,
            finishedTyping: false
        };
    },
    methods: {
        toggleDisableTextResponse: function() {
            this.disableTextResponse = !this.disableTextResponse;
        }
    }
}
</script>
