<template>
    <div class="welcome">
        <div class="welcome-content mb-4" :style="{ color: styles.color }">
            <v-img
                v-if="survey.welcome_page_file_url"
                height="120"
                width="120"
                :src="survey.welcome_page_file_url"
                class="welcome-image"
            />
            <div
                class="text-h4 welcome-title"
            >
                {{ formTitle }}
            </div>

            <div
                class="welcome-subtitle"
                v-html="formSubtitle"
            >
            </div>

            <next-button v-if="isActiveStep"></next-button>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import NextButton from '../NextButton.vue';
import Handlebars from "handlebars";

export default {
    components: { NextButton },
    name: "WelcomeStep",
    computed: {
        ...mapGetters({
            styles:'form/styles',
            survey: 'form/survey',
            activeStep: 'form/activeStep',
            passingVariables: 'form/passingVariables'
        }),
        isActiveStep(){
            if(this.activeStep && this.activeStep.step){
                return this.activeStep.step === 'welcome_screen'
            }
            return false
        },
        formTitle(){
            if(this.survey && this.survey.title){
                try {
                    var compiled = Handlebars.compile(this.survey.title);
                    return compiled(this.passingVariables);
                } catch (error) {
                    console.log(error);
                    return this.survey.title
                }
            }
            return ''
        },
        formSubtitle(){
            if(this.survey && this.survey.subtitle){
                try {
                    var compiled = Handlebars.compile(this.survey.subtitle);
                    return compiled(this.passingVariables);
                } catch (error) {
                    console.log(error);
                    return this.survey.subtitle
                }
            }
            return ''
        }
    },
    props: ["contrastTextColor"],
};
</script>

<style lang="scss" scoped>
.welcome {
    &-image {
        border-radius: 50%;
        border: solid 5px #ffffff50;
    }

    &-content {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
    }

    &-title {
        padding-top: 32px;
        font-size: 30px !important;
        text-align: center;
    }
    &-subtitle {
        padding-top: 20px;
        font-family: Poppins;
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 31px;
        max-width: 400px;
        text-align: center;
    }
    &-overline {
        max-width: 420px;
        margin-top: 8px;
        font-weight: 300;
        font-size: 14px;
        line-height: 17px;
    }

    &-raw {
        display: flex;
        align-items: center;
    }
}
</style>
