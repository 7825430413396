<template>
    <div :class="['question', {'my-10': !singleQuestionWidget}]" :style="{'max-width': step.type == questionTypes.MATRIX? '900px': '700px' }">
        <div
            v-if="step.type == questionTypes.VOICE_QUESTION || step.audio_url"
            class="audio-question-container mb-4"
        >
                <audio controls :src="step.audio_url" ref="player"></audio>
        </div>
        <div :style="{color: survey.style ? survey.style.color : survey.font_color}">

        </div>
        <div v-if="!hideQuestion" :class="{'text-h5 mb-2':true, 'question-required': !this.step.is_optional}" :style="{color: survey.style ? survey.style.color : survey.font_color}">
            {{ questionText }}
        </div>
        <div v-if="description" v-html="description" class="question--description description-content" :style="{color: contrastTextColor}">
        </div>
        <v-fade-transition>
            <video-attachment v-if="step.video_source && isActiveStep" />
        </v-fade-transition>
        <media-attachment  :attachments="step.media_attachments" :survey="survey" />
        <div class="mt-4" >
            <slot :name="slotName"></slot>
        </div>
        <template v-if="messages && messages.length > 0">
            <follow-up-message :typingEffect="i+1 === messages.length" v-for="(message, i) in messages" :nextMessage="messages && messages.length && messages[i+1]" :key="`${i}-${step.id}-message`" :message="message" :activeStep="step"></follow-up-message>
        </template>
        <next-button v-if="isActiveStep" :step="step"></next-button>
    </div>
</template>

<script>
import questionTypes from "@/configs/questionTypes";
import MediaAttachment from '../MediaAttachment.vue';
import VideoAttachment from '../VideoAttachment.vue';
import NextButton from '../NextButton.vue';
import { mapGetters } from 'vuex';
import FollowUpMessage from "../questions/FollowUpMessage.vue";
import Handlebars from "handlebars";
export default {
    name: "QuestionStep",
    props: {
        step: {
            required: true,
        },
        contrastTextColor: {
            required: true,
        },
        survey: {
            required: true
        }
    },
    components: { MediaAttachment, NextButton, VideoAttachment, FollowUpMessage },
    data() {
        return {
            paused: true,
            questionTypes,
        };
    },
    methods: {
        audioPlay(isPaused) {
            if (isPaused) {
                this.$refs.player.play();
                this.paused = false;
            } else {
                this.$refs.player.pause();
                this.paused = true;
            }
        }
    },
    computed:{
        ...mapGetters({
            activeStep: 'form/activeStep',
            passingVariables: 'form/passingVariables',
            loopLists: 'form/loopLists',
            singleQuestionWidget: 'form/singleQuestionWidget',
        }),
        loopListItemData(){
            const questionId = this.step.questionId
            if(this.loopLists && this.loopLists[questionId] && this.loopLists[questionId].length > 0){
                return this.loopLists[questionId][this.step.loopIndex] || {}
            }
            return null
        },
        interpolationData(){
            return {
                ...this.passingVariables,
                loop_item: this.loopListItemData,
            }
        },
        isActiveStep(){
            if(this.activeStep && this.activeStep.id && this.step.id){
                return this.activeStep.id === this.step.id
            }
            return false
            
        },
        questionText(){
            if(this.step.text && this.step.text.trim() === 'Type your question here'){
                return ''
            }
            if(this.step.text){
                if(this.step.type=== questionTypes.VOICE_QUESTION && this.step.text.trim() === ''){
                    return "Audio Question"
                }
                try {
                    var compiled = Handlebars.compile(this.step.text, {noEscape: true});
                    return compiled(this.interpolationData);
                } catch (error) {
                    console.log(error);
                    return this.step.text
                }
            }
            return ""
        },
        slotName(){
            if(this.step.type === questionTypes.VOICE_QUESTION){
                return questionTypes.VOICE_RESPONSE
            }
            return this.step.type
        },
        messages(){
            if (
                this.$store &&
                this.$store.state &&
                this.$store.state.form &&
                this.$store.state.form.dialogues &&
                this.step &&
                this.step.id
            ) {
                return this.$store.state.form.dialogues[this.step.id] || [];
            }
            return [];
        },

        description(){
            if(this.step.description){
                try {
                    var compiled = Handlebars.compile(this.step.description);
                    return compiled(this.interpolationData);
                } catch (error) {
                    console.log(error);
                    return this.step.description
                }
            }
            return this.step.description ? this.step.description : ''
        },
        hideQuestion(){
            return this.$route?.query?.v_hide_question === 'true'
        }
    },
    watch: {
        step: {
            deep: true,
            handler(newVal, oldVal) {
                if (newVal.audio_url === oldVal.audio_url) {
                    return;
                }
                this.$refs.player.load();
            }
        },
    },
    mounted() {
        if(this.step.audio_url && this.step.properties.audio_attachment_play_on_mount){
            this.audioPlay(true);
        }
    }
};
</script>

<style lang="scss" scoped>
.audio-question-container {
    display: flex;
    // justify-content: center;
    padding: 8px
}
.question {
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 700px;
    
    &-required::after{
        content: "*";
        
    }
    .audio-play {
        width: 100%;
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1.4rem;
        align-items: center;
        grid-template-columns: max-content 1fr;

        &--button {
            background: #000000;
            border-radius: 8px;
            cursor: pointer;

            height: max-content;
            font-weight: 700;
            font-size: 17px;
            line-height: 21px;

            color: #ffffff;
            padding: 10px;
            display: flex;

            .play-icon {
                border: 0;
                background: transparent;
                box-sizing: border-box;
                width: 0;
                height: 10px;

                border-color: transparent transparent transparent #ffffff;
                transition: 100ms all ease;
                cursor: pointer;

                border-style: solid;
                border-width: 5px 0 5px 8px;

                &.pause {
                    border-style: double;
                    border-width: 0px 0 0px 8px;
                }
            }
        }
    }
}

.description-content::v-deep{
    word-break: break-word;

    img{
        border-radius: 4px;
        margin-top: 12px;
        margin-bottom: 12px;
        height: auto !important;
    }

    h1, h2, h3{
        margin-top: 12px;
        margin-bottom: 12px;
    }

    h1{
        font-size: 3rem;
        font-weight: 500;
        line-height:unset;
        letter-spacing: normal;
        text-transform: unset;
    }

    h2{
        font-size: 2.25rem;
        font-weight: 500;
        line-height:unset;
        letter-spacing: 0.0125em;
        text-transform: unset;
    }

    h3{
        font-size: 1.5rem;
        font-weight: 500;
        line-height:unset;
        letter-spacing: 0.015em;
        text-transform: unset;
    }

    hr{
        margin-top: 12px;
        margin-bottom: 12px;
        opacity: 0.5;
    }


    // standard bulleted list with bullets
    ul {
        list-style-type: disc;
        padding-left: 20px;
    }

    // standard numbered list with numbers
    ol {
        list-style-type: decimal;
        padding-left: 20px;
    }

    // nice quote look with italics and lightened color
    blockquote {
        font-style: italic;
        color: inherit;
        opacity: 0.7;
        border-left: 4px solid #ccc;
        margin-left: 0;
        padding-left: 10px;
    }


    .align-left {
        display: block;
        margin-left: 0;
        margin-right: auto; /* Ensures it aligns left */
    }

    .align-right {
        display: block;
        margin-left: auto;
        margin-right: 0; /* Ensures it aligns right */
    }

    .align-center {
        display: block;
        margin-left: auto;
        margin-right: auto; /* Ensures it aligns center */
    }
}
</style>
