import { render, staticRenderFns } from "./MediaAttachment.vue?vue&type=template&id=38e91b55&scoped=true&"
import script from "./MediaAttachment.vue?vue&type=script&lang=js&"
export * from "./MediaAttachment.vue?vue&type=script&lang=js&"
import style0 from "./MediaAttachment.vue?vue&type=style&index=0&id=38e91b55&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38e91b55",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBadge,VBtn,VCarousel,VCarouselItem,VImg,VOverlay,VRow,VSheet})
