<template>
    <div class="d-flex flex-grow-1 justify-center justify-sm-start">
        <div class="d-flex flex-column">
            <div
                v-if="$vuetify.breakpoint.xs"
                class="d-flex flex-column"
            >
                <p class="mb-4">0 → Not likely at all</p>
                <p class="mb-4">10 → Extremely likely</p>
            </div>
            <v-btn-toggle v-model="answer" background-color="transparent" class="d-flex flex-wrap justify-center">
                <v-btn
                    v-for="(n, i) in 11"
                    :key="i"
                    :class="`my-2 rounded mr-${i === 10 ? '0' : '2'}`"
                    :color="i === answer ? styles.button.background : 'transparent'"
                    :style="`color: ${i === answer ? styles.button.color : styles.button.background}; 
                             height: ${$vuetify.breakpoint.xs ? 64 : 48}px;
                             opacity: 0.7;
                             border: 1px solid ${styles.button.background} !important`"
                >
                    {{i}}
                </v-btn>
            </v-btn-toggle>
            <div
                v-if="!$vuetify.breakpoint.xs"
                class="d-flex justify-space-between mt-4"
            >
                <p>0 → Not likely at all</p>
                <p>10 → Extremely likely</p>
            </div>
        </div>
    </div>
</template>

<script>

import { mapGetters } from 'vuex';

export default {
    name: "NetPromoterScore",
    props: {
        value: {
            required: true,
        },
    },

    computed: {
        ...mapGetters({
            styles: 'form/styles'
        }),
        answer: {
            get: function () {
                return this.value ? this.value.value : null;
            },
            set: function (val) {
                if(val || val === 0) {
                    this.$emit("input", { type: "number", value: val });
                } else {
                    this.$emit("input", null);
                }
                this.$emit("change");
            },
        },
    },
    // check if url router query has nps value and set it to answer
    mounted(){
        if(this.$route.query.v_nps){
            // check if nps value is a number
            if(!isNaN(this.$route.query.v_nps)){
                this.answer = parseInt(this.$route.query.v_nps);
            }
        }
    }
};
</script>