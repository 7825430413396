<template>
    <Dashboard :uppy="uppy" :props="{
        proudlyDisplayPoweredByUppy: false,
        width: '100%',
        doneButtonHandler: null,
        singleFileFullScreen: false,
        showRemoveButtonAfterComplete: true,
        theme: dark? 'dark' : 'light',
        locale: dashboardLocale,
        disableLocalFiles
    }" :plugins="plugins" />
</template>

<script>
import { Dashboard } from '@uppy/vue';
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import AwsS3 from '@uppy/aws-s3';
import ScreenCapture from '@uppy/screen-capture';


// Don't forget the CSS: core and UI components + plugins you are using
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/screen-capture/dist/style.min.css';

import {isEqual} from 'lodash'


import { mapGetters } from 'vuex';
export default {
    name: "FileUpload",
    props: {
        value: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        survey: {
            required: true,
        },
        dark: {
            required: true,
        },
    },
    components: {
        Dashboard
    },
    computed: {
        ...mapGetters({
            answers: 'form/answers'
        }),
        disableLocalFiles(){
            return  this.activeStep?.properties?.local_files === false
        },
        filesTypes(){
            return this.activeStep?.properties?.file_types || null 
        },
        plugins() {
            let plugins = []
            if(this.activeStep.properties.camera!==false){
                plugins.push('Webcam')
            }
            if(this.activeStep.properties.screencast !== false){
                plugins.push('ScreenCapture')
            }
            return plugins
        },
        dashboardLocale(){

            if(this.filesTypes === 'videos'){
                let importFiles = 'Record or upload a video via:'

                if(this.disableLocalFiles){
                    importFiles = 'Select a video recording option:'

                    if(this.activeStep?.properties.camera === false){
                        importFiles = 'Hit a button to screen record a video'
                    }

                    if(this.activeStep?.properties.screencast === false){
                        importFiles = 'Hit a button to record a video'
                    }

                    if(this.activeStep?.properties.camera === false && this.activeStep?.properties.screencast === false){
                        importFiles = 'No options available to record a video'
                    }
                }
                return {
                    strings: {
                        importFiles,
                        dropPasteImportFiles: 'Record or upload a video here:',
                        myDevice: 'Upload video',
                    }
                }
            }
            return null
        },
        uppy(){

            const webcamConfig = this.filesTypes === 'videos' ? {
                mirror: true,
                facingMode: 'user',
                modes: ['video-audio'],
                showRecordingLength: true,
                locale: {
                    strings: {
                        pluginNameCamera: 'Record video',
                    }
                },
            }: {};

            const restrictions = {
                maxTotalFileSize: 209715200,
                allowedFileTypes: this.activeStep.properties.file_types_patterns || undefined,
            }

            if(this.activeStep.properties.max_number_of_files && this.activeStep.properties.max_number_of_files > 0){
                restrictions.maxNumberOfFiles = this.activeStep.properties.max_number_of_files
            }

            if(this.activeStep.properties.min_number_of_files && this.activeStep.properties.min_number_of_files > 0){
                restrictions.minNumberOfFiles = this.activeStep.properties.min_number_of_files
            }

            return new Uppy({
                // debug: false,
                // logger: {
                //     debug: console.log,
                //     info: console.log,
                //     warn: console.log,
                //     error: console.log
                // },
                meta: {
                    formId: this.survey.id
                },
                restrictions
            })
            .use(Webcam, webcamConfig)
            .use(ScreenCapture)
            .use(AwsS3, {
                    // shouldUseMultipart: (file) => file.size > 100 * 2 ** 20,
                    companionUrl: process.env.VUE_APP_COMPANION_URL || 'https://file-uploader.voiceform.com',
                    allowedMetaFields: ['formId'],
            })
            .on('state-update', (prev, state) => {
                if(isEqual(prev.files, state.files)){
                    return;
                }
                
                let uploadedAllFiles = true;
                const files = Object.values(state.files).reduce((previousValue, currentValue)=> {
                    if(currentValue && currentValue.response && currentValue.response.body && currentValue.response.body.key){
                        previousValue.push({
                            url: `https://cdn.voiceform.com/${currentValue.response.body.key}`,
                            name: currentValue.meta.name,
                            type: currentValue.meta.type,
                            size: currentValue.size,
                        })
                    }else{
                        uploadedAllFiles = false;
                    }
                    return previousValue;
                }, []);

                if(files && files.length > 0){
                    return this.$emit("input", {
                            answer: { type: "files", value: files },
                            valid: uploadedAllFiles || 'Please upload all files or remove the ones you don\'t want to upload.',
                            fileUploaderStore: JSON.stringify(state.files)
                    });
                }else{
                    this.$emit("input", null);
                }
                // TODO: we need to store file upload state to restore in case of page reload

            });
        },
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val && val.length > 0){
                    this.$emit("input", {
                        answer: { type: "files", value: val },
                        valid: true
                    });
                }else{
                    this.$emit("input", null);
                }
            },
        },
    },
    mounted() {
        try {
            if (!this.answers || !this.activeStep) return;

            const currentStepAnswers = this.answers[this.activeStep.id];
            if (!currentStepAnswers || !currentStepAnswers.fileUploaderStore) return;

            const fileStore = typeof currentStepAnswers.fileUploaderStore === 'string' ? JSON.parse(currentStepAnswers.fileUploaderStore) : currentStepAnswers.fileUploaderStore;

            const newObject = Object.entries(fileStore).reduce((acc, [key, file]) => {
                acc[key] = { ...file, preview: file?.meta?.key? `https://cdn.voiceform.com/${file.meta.key}`:null };
                return acc;
            }, {});

            this.uppy.setState({ files: newObject });
        } catch (error) {
            console.error("Error in file upload restore", error);
        }

    }
};
</script>

<style lang="scss" scoped>
</style>
