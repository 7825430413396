var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Dashboard',{attrs:{"uppy":_vm.uppy,"props":{
    proudlyDisplayPoweredByUppy: false,
    width: '100%',
    doneButtonHandler: null,
    singleFileFullScreen: false,
    showRemoveButtonAfterComplete: true,
    theme: _vm.dark? 'dark' : 'light',
    locale: _vm.dashboardLocale,
    disableLocalFiles: _vm.disableLocalFiles
},"plugins":_vm.plugins}})}
var staticRenderFns = []

export { render, staticRenderFns }