import axios from 'axios'
export default {
    probe({surveyId, audioPath, messages, text, excludeFollowUp, vocabulary}){
        return axios.post('/api/probe', {
            surveyId,
            audioPath,
            messages,
            text,
            excludeFollowUp,
            vocabulary,
        }, {
            timeout: 20000 // 15 seconds
        });
    }
}