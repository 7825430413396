<template>
    <td :style="{'min-width': col.width + 'px !important'}">
        <template v-if="col.type === 'checkbox'">
            <v-checkbox light :inputValue="includesInCheckbox" @click="handleCheckboxChange" hide-details="auto" class="ma-0 pa-0" :name="`row-${row.id}`"></v-checkbox>
        </template>
        <template v-else-if="col.type === 'radio'">
            <v-radio-group :value="selectionValue && selectionValue.col_id" class="pa-0 ma-0" hide-details="auto">
                <v-radio light @click="handleRadioChange" :value="col.id"  hide-details="auto" class="ma-0 pa-0" :name="`row-${row.id}`"></v-radio>
            </v-radio-group>
        </template>
        <template v-else-if="col.type === 'text'">
            <v-text-field light :rules="[required]" ref="input" @click:clear="handleClear" :value="selectionValue && selectionValue.value || ''" @change="handleTextChange" class="ma-0 pa-0 text-caption" :placeholder="col.properties.field_placeholder || ''" clearable style="min-width: 200px;" hide-details="auto" dense outlined></v-text-field>
        </template>
        <template v-else-if="col.type === 'numeric'">
            <matrix-numeric-input :selectionValue="selectionValue" :value="selectionValue && selectionValue.value || ''" @change="handleNumberChange" :col="col" :row="row"></matrix-numeric-input>
        </template>
        <template v-else-if="col.type === 'dropdown'">
            <v-select light :rules="[required]" ref="input" @click:clear="handleClear" :value="selectionValue && selectionValue.value || ''" @change="handleDropdownChange" :items="dropdownItems" return-object item-text="value" item-value="id" class="ma-0 pa-0 text-caption" clearable  style="min-width: 200px;" hide-details="auto" dense outlined></v-select>
        </template>
    </td>
</template>

<script>
import MatrixNumericInput from './MatrixNumericInput.vue';

const DEFAULT_RADIO_GROUP_NAME = 'default_radio';
const DEFAULT_CHECKBOX_GROUP_NAME = 'default_checkbox';
export default {
  components: { MatrixNumericInput },
    name: "MatrixCell",
    props: {
        row: {
            required: true,
        },
        col: {
            required: true,
        },
        answer: {
            required: false,
            default: null,
        },
    },
    data(){
        return {
            DEFAULT_RADIO_GROUP_NAME,
            DEFAULT_CHECKBOX_GROUP_NAME,
            required: value => {
                if (!this.col.optional && !value) {
                    return "Required.";
                }
                return true;
            },
        }
    },
    methods: {
        handleCheckboxChange(){
            this.$emit('update:answer', {row_id: this.row.id, row_title: this.row.title || null, col_id: this.col.id, value: this.col.title, type: this.col.type, group_name: this.group_name, col_title: this.col.title})
        },
        handleRadioChange(){
            this.$emit('update:answer', {row_id: this.row.id, row_title: this.row.title || null, col_id: this.col.id, value: this.col.title, type: this.col.type, group_name: this.group_name, col_title: this.col.title})
        },
        handleTextChange(value){
            // check if valid
            const valid = this.validateValue(value, [this.required]);
            this.$emit('update:answer', {row_id: this.row.id, row_title: this.row.title || null, col_id: this.col.id, value, valid, type: this.col.type, group_name: this.group_name,  col_title: this.col.title})
        },
        handleNumberChange(numericInputValue){
    
            this.$emit('update:answer', {row_id: this.row.id, row_title: this.row.title || null, col_id: this.col.id, value: numericInputValue?.value || null, valid: numericInputValue?.valid || null, type: this.col.type, group_name: this.group_name,   col_title: this.col.title})
        },
        handleDropdownChange(value){
            this.$emit('update:answer', {row_id: this.row.id, row_title: this.row.title || null, col_id: this.col.id, value: value || null, type: this.col.type, group_name: this.group_name, col_title: this.col.title})
        },
        handleClear(){
            this.$refs?.input?.blur()
        },
        validateValue(value, rules =[]) {
            for (const validate of rules) {
                const result = validate(value);
                if (result !== true) {
                    return result; // This will be the error message string
                }
            }
            return true; // All validations passed
        },
    },
    computed: {
        group_name(){
            if(this.col.group_name){
                return this.col.group_name
            }
            if(this.col.type === 'radio'){
                return DEFAULT_RADIO_GROUP_NAME
            }
            if(this.col.type === 'checkbox'){
                return DEFAULT_CHECKBOX_GROUP_NAME
            }
            return null
        },
        selectionValue(){
            if(!this.answer || !this.answer[this.row.id]){
                return null
            }
            const col_key = ['radio', 'checkbox'].includes(this.col.type) ? this.group_name : this.col.id
            return this.answer && this.answer[this.row.id] && this.answer[this.row.id][col_key] || null
        },
        includesInCheckbox(){
            if(!this.selectionValue || this.col.type !== 'checkbox'){
                return false
            }

            return this.selectionValue && this.selectionValue.some(item => item.col_id === this.col.id)
        },
        dropdownItems(){
            if(this.col.type !== 'dropdown'){
                return []
            }
            return this.col.properties.options || []
        },
    },
}
</script>