<template>
    <form class="ma-0 pa-0" ref="form" v-on:submit.prevent>
        <!-- loading state -->
        <div v-if="loading && !error">
            <skeleton-loader-vue
                type="rect"
                :height="36"
                animation="wave"
                rounded
                radius="4"
                class="skeleton"
                color="rgba(0,0,0,0.05)"
            />
            <div class="mt-4"></div>
            <skeleton-loader-vue
                type="rect"
                :height="16"
                :width="250"
                animation="wave"
                rounded
                radius="4"
                class="skeleton"
                color="rgba(0,0,0,0.05)"
            />
            <div class="mt-4"></div>
            <skeleton-loader-vue
                type="rect"
                :height="16"
                :width="150"
                animation="wave"
                rounded
                radius="4"
                class="skeleton"
                color="rgba(0,0,0,0.05)"
            />
            <div class="mt-4"></div>
            <skeleton-loader-vue
                type="rect"
                :height="16"
                :width="180"
                animation="wave"
                rounded
                radius="4"
                class="skeleton"
                color="rgba(0,0,0,0.05)"
            />
            <div class="mt-5"></div>
            <skeleton-loader-vue
                type="rect"
                :height="44"
                :width="110"
                animation="wave"
                rounded
                radius="4"
                class="skeleton"
                color="rgba(0,0,0,0.05)"
            />
        </div>
        <v-card v-else-if="empty || expired || notPublishedError || error" dark outlined color="error darken-1" class="pa-4">
            <v-icon left color="white">mdi-alert-circle</v-icon>
            <span>{{ errorMessage }}</span>
        </v-card>
        <!-- question step -->
        <single-question-layout v-else-if="survey && !loading" :step="steps && steps[0]"></single-question-layout>
    </form>
</template>

<script>
import axios from 'axios';
import { mapActions, mapGetters } from 'vuex';
import SingleQuestionLayout from './SingleQuestionLayout.vue';
export default {
  components: { SingleQuestionLayout },
    name: "SingleQuestion",
    data: () => ({
        loading: false,
        expired: false,
        empty: false,
        error: false,
        survey: null,
        notPublishedError: false
    }),
    computed: {
        ...mapGetters({
            steps: 'form/steps',
            styles: 'form/styles',
        }),
        errorMessage(){
            if(this.notPublishedError){
                return 'This survey is not published yet.'
            }

            if(this.empty){
                return "The question you are looking for is not available."
            }

            if(this.expired){
                return "This survey has expired."
            }

            return "Something went wrong. Please try again."
        }
    },
    methods:{
        ...mapActions({
            initForm: 'form/initForm',
        }),

        async loadQuestion(){
            try {
                this.loading = true
                const params = {};
                // TODO: pass question id
                const { data: surveyData } = await axios.get(`/api/surveys-public/${this.$route.params.hash}`, {
                    params: params
                });
                const endDate = surveyData.ends_at ? new Date(surveyData.ends_at) : null;
                if (endDate && endDate.getTime() <= new Date().getTime()) { 
                    this.expired = true
                }
                if(surveyData && surveyData.questions){
                    const question = surveyData.questions.find(question => question.type !== 'description-slide' && question.id === this.$route.params.questionId)
                    if(!question){
                        this.empty = true
                    }else{
                        surveyData.questions = [question]
                    }
                }
                // disable welcome page
                surveyData.settings.hide_welcome_step = true
                // find question and keep only that question
    
    
                this.survey = surveyData
    
                if(this.notPublishedError){
                    this.notPublishedError = false
                }
    
                surveyData.resumable = false
                this.initForm(surveyData)
                this.loading = false
                this.error = false
            } catch (error) {
                console.error(error)
                // check if the survey is not published
                if(error.response && error.response.status === 422 && error.response.data && error.response.data.reason === 'not_published'){
                    this.notPublishedError = true
                }
                this.loading = false
                this.error = true
            }
        },
        sendHeight() {
          const form = this.$refs.form;
          if (form) {
            const height = form.scrollHeight;
            window.parent.postMessage({ type: 'voiceform.contentHeight', height: height, questionId:  this.$route.params.questionId}, '*');
          }
        },
    },
    created(){
        this.loadQuestion();
    },
    mounted() {
        // Observe the form height changes
        this.observer = new ResizeObserver(() => {
            this.sendHeight();
        });
        this.observer.observe(this.$refs.form);
    },
    beforeDestroy() {
        // Disconnect the observer when the component is destroyed
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
</script>
