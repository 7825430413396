<template>
    <div>
        <template v-for="(choice, i) in mcqItems">
            <v-checkbox
                v-if="choice"
                :key="i"
                v-model="answer"
                :value="choice"
                :label="choice"
                :disabled="!choice || !choice.trim()"
                :dark="dark"
                :color="survey.style.color || primary"
                @change="$emit('change')"
            />
        </template>
    </div>
</template>

<script>
import {shuffleArray} from '@/utils/arrayOperations';
export default {
    name: "Checkbox",
    props: {
        value: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        survey: {
            required: true,
        },
        dark: {
            required: true,
        },
    },

    methods:{
        validate(value){

            if(this.minProperty && value.length < this.minProperty){
                return `You must select at least ${this.minProperty} options`
            }

            if(this.maxProperty && value.length > this.maxProperty){
                return `You must select no more than ${this.maxProperty} options`
            }

            return true

        }
    },

    computed: {
        answer: {
            cache: false,
            get: function () {
                return this.value ? this.value.value : [];
            },
            set: function (val) {
                if (val) {
                    if(val.length){
                        if(this.noneOfTheAboveProperty && val.includes("None of the above")) {
                            return this.$emit("input",
                            {
                                answer: {
                                    type: "checkbox",
                                    value: ["None of the above"],
                                },
                                valid: true
                            });
                        }

                        const valid = this.validate(val)

                        return this.$emit("input", val?{
                            answer: {
                                type: "checkbox",
                                value: val,
                            },
                            valid: valid || 'Invalid Answer.'
                        }: null);
                    }
                    return this.$emit("input", null)

                }
            },
        },
        noneOfTheAboveProperty() {
            return this.activeStep.properties && this.activeStep.properties.add_none_of_the_above;
        },
        mcqItems() {
            let items = [];
            if(this.activeStep?.properties?.randomize_options){
                const multipleChoiceItems = this.activeStep.multipleChoiceItems;
                const excludedItems = this.activeStep?.properties?.excluded_from_randomization;
                const fixedIndices = excludedItems && excludedItems.length? multipleChoiceItems.reduce((indices, item, index) => {
                    if (excludedItems.includes(item.value)) {
                        indices.push(index);
                    }
                    return indices;
                }, []): [];

                items = shuffleArray(this.activeStep.multipleChoiceItems, fixedIndices);
            } else {
                items = this.activeStep.multipleChoiceItems;
            }
            const choices = new Set(Array.from(items, choice => choice.value));
            // add none of the above option
            if(this.noneOfTheAboveProperty) {
                choices.add("None of the above");
            }
            return choices
        },
        minProperty(){
            if(typeof this.activeStep?.properties?.min_choices_number === 'number'){
                return this.activeStep.properties.min_choices_number
            }
            return null
        },
        maxProperty(){
            if(typeof this.activeStep?.properties?.max_choices_number === 'number'){
                return this.activeStep.properties.max_choices_number
            }
            return null
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
