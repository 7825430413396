<template>
    <v-select
        ref="select"
        :items="items"
        item-text="value"
        item-value="value"
        outlined
        v-model="answer"
        dense
        :dark="dark"
        :color="survey.style.color"
        @change="$emit('change')"
        :menu-props="{bottom: true, offsetY: true}"
        clearable
        @click:clear="answer = null"
        @keydown="handleKeydown"
    ></v-select>
</template>

<script>
import {shuffleArray} from '@/utils/arrayOperations';
export default {
    name: "Dropdown",
    props: {
        value: {
            required: true,
        },
        activeStep: {
            required: true,
        },
        survey: {
            required: true,
        },
        dark: {
            required: true,
        },
    },
    data() {
        return {
            dropdown: false,
        };
    },

    computed: {
        answer: {
            get: function () {
                return this.value ? this.value.value : "";
            },
            set: function (val) {
                if(val === null) {
                    return this.$emit("input", null);
                }
                this.$emit("input", { type: "dropdown", value: val });
            },
        },
        items(){
            let items = [];
            if(this.activeStep?.properties?.randomize_options){
                const multipleChoiceItems = this.activeStep.multipleChoiceItems;
                const excludedItems = this.activeStep?.properties?.excluded_from_randomization;
                const fixedIndices = excludedItems && excludedItems.length? multipleChoiceItems.reduce((indices, item, index) => {
                    if (excludedItems.includes(item.value)) {
                        indices.push(index);
                    }
                    return indices;
                }, []): [];

                items = shuffleArray(this.activeStep.multipleChoiceItems, fixedIndices);
            } else {
                items = this.activeStep.multipleChoiceItems;
            }
            return items
        },
    },
    methods: {
        handleKeydown(e){
            e.preventDefault()
            if(this.$refs.select) {
                e.stopPropagation()
                setTimeout(()=>{
                    this.$refs.select.isMenuActive = false;
                }, 10)
            }
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
